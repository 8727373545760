import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Hero from '../../components/hero/hero';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import About from '../../components/about/about';
import PolicySection from '../../components/PolicySection/PolicySection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/CtaSection/CtaSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { Helmet } from 'react-helmet';
import Popup from '../../components/pop-up/pop-up';

const HomePage = () => {

    return (
        <Fragment>
            <div>
                <Header />
                <main className="page_content">
                <Helmet>
                    <title>XNovaQ | Top IT Career Program In Coimbatore</title>
                    <meta
                    name="description"
                    content="Start your IT Career with Coimbatore's top-rated program. Get an IT job and salary from day 1, hands-on training, and expert mentorship."
                    />
                </Helmet>
                    <Hero />
                    <FeaturesSection />
                    <About />
                    <PolicySection />
                    <ServiceSection />
                    <Popup />
                    <ProjectSection />
                    <BlogSection />
                    <CtaSection />
                </main>
                <Footer />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default HomePage;