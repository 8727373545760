import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

export default function AppointmentForm() {
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const [forms, setForms] = useState({
    full_name: '',
    email: '',
    phone: '',
    message: '',
    city: '',
    highest_degree: '',
    job_title: '',
    companyName: '',
    companySize: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [validator] = useState(new SimpleReactValidator({
    className: 'errorMessage'
  }));
  const changeHandler = e => {
    setForms({ ...forms, [e.target.name]: e.target.value })
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };
  const thanksNavigateClick = () => {
    navigate('/thanks');
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      try {
        const response = await fetch('https://xnovaq.com/xnova-register/xnovaq-register-api.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(forms)
        });

        const data = await response.json();

        if (data.status === 'success') {
          setResponseMessage(data.message);
          validator.hideMessages();
          setForms({
            full_name: '',
            email: '',
            phone: '',
            message: '',
            city: '',
            highest_degree: '',
            job_title: '',
            companyName: '',
            companySize: ''
          })
          thanksNavigateClick();
        } else if (data.status === 'error') {
          setResponseMessage(Object.values(data.message).join(', '));
        }
      } catch (error) {
        console.error('Error:', error);
        setResponseMessage('An error occurred while registering.');
      }

    } else {
      validator.showMessages();
    }
  };

  return (
    <form action="#" className="row" onSubmit={(e) => submitHandler(e)}>
      <div className="form-group col-lg-6">
        <label className="cs_input_label cs_heading_color">Full Name</label>
        <input
          value={forms.full_name}
          type="text"
          className="cs_form_field"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          name='full_name'
          placeholder="David John" />
        {validator.message('name', forms.full_name, 'required|alpha_space')}
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="form-group col-lg-6">
        <label className="cs_input_label cs_heading_color">Email Address</label>
        <input
          value={forms.email}
          type="text"
          className="cs_form_field"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          name='email'
          placeholder="abc@gmail.com" />
        {validator.message('email', forms.email, 'required|email')}
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="form-group col-lg-6">
        <label className="cs_input_label cs_heading_color">Phone Number</label>
        <input
          value={forms.phone}
          type="text"
          className="cs_form_field"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          name='phone'
          placeholder="(123) 456 - 789"
        />
        {validator.message('phone', forms.phone, 'required|phone')}
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="form-group col-lg-6">
        <label className="cs_input_label cs_heading_color">Company Name</label>
        <input
          value={forms.companyName}
          type="text"
          className="form-control cs_form_field"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          name='companyName'
          placeholder="abc" />
        {validator.message('companyName', forms.companyName, 'required|alpha_space')}
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className=" form-group col-lg-6">
        <label className="cs_input_label cs_heading_color">Company Size</label>
        <input
          value={forms.companySize}
          type="number"
          className="form-control cs_form_field"
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          name='companySize'
          placeholder="1 to 100" />
        {validator.message('companySize', forms.companySize, 'required')}
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="form-group col-lg-6">
        <label className="input_title cs_input_label cs_heading_color">Job Title</label>
        <input
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          value={forms.job_title}
          type="text"
          name="job_title"
          className="form-control cs_form_field"
          placeholder="Java Developer" />
        {validator.message('city', forms.job_title, 'required|alpha_space')}
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="form-group col-lg-6">
        <label className="input_title" htmlFor="input_textarea">Message</label>
        <textarea
          onBlur={(e) => changeHandler(e)}
          onChange={(e) => changeHandler(e)}
          value={forms.message}
          type="text"
          name="message"
          className="form-control"
          placeholder="How can we help you?">
        </textarea>

      </div>
      {/* <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Medical Record Number
        </label>
        <input
          type="text"
          className="cs_form_field"
          placeholder="123456-7890-0987"
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Preferred Date
        </label>

        <div className="cs_with_icon_input">
          <DatePicker
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            isClearable
            placeholderText="dd/mm/yyyy"
          />
          <i>
            <Icon icon="fa6-solid:calendar-days" />
          </i>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Preferred Time
        </label>
        <div className="cs_with_icon_input">
          <input
            type="time"
            className="cs_form_field cs_timepicker"
            placeholder="10:00AM"
          />
          <i>
            <Icon icon="fa6-regular:clock" />
          </i>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Reason for Visit
        </label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="routineCheckup"
              defaultValue="routineCheckup"
            />
            <label className="cs_radio_label" htmlFor="routineCheckup">
              Routine Checkup
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="newPatientVisit"
              defaultValue="newPatientVisit"
              defaultChecked=""
            />
            <label className="cs_radio_label" htmlFor="newPatientVisit">
              New Patient Visit
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="specificConcern"
              defaultValue="specificConcern"
            />
            <label className="cs_radio_label" htmlFor="specificConcern">
              Specific Concern
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Department</label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="pediatric"
              defaultValue="pediatric"
            />
            <label className="cs_radio_label" htmlFor="pediatric">
              Pediatric
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="obstetricsGynecology"
              defaultValue="obstetricsGynecology"
              defaultChecked=""
            />
            <label className="cs_radio_label" htmlFor="obstetricsGynecology">
              Obstetrics and Gynecology
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="cardiology"
              defaultValue="cardiology"
            />
            <label className="cs_radio_label" htmlFor="cardiology">
              Cardiology
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="neurology"
              defaultValue="neurology"
            />
            <label className="cs_radio_label" htmlFor="neurology">
              Neurology
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div> */}
      <div className="col-lg-12">
        <button className="cs_btn cs_style_1">
          <span>Submit</span>
          {/* <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i> */}
        </button>
      </div>
    </form>
  );
}
