import CtaSection from '../../components/CtaSection/CtaSection';
import blogs from '../../api/blogs'
import Bg from '../../images/backgrounds/bg_image_4.webp'
import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom';
import ContactSection from '../../components/software-company-components/ContactSection';
const ServiceHome = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <div className='index_business_consulting'>
                <main className="page_content s2">
                    <Header />
                    <section className="blog_section_2 section_space mt-5" style={{ backgroundImage: `url(${Bg})` }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7">
                                    <div className="heading_block text-center" style={{ marginTop: '100px' }}>
                                        <h2 className="heading_text">
                                            Discover the Best Hiring Service for Your Business Needs
                                        </h2>
                                        <p className="heading_description text-dark mb-0">
                                            Experience the XNovaQ Difference
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {blogs.slice(8, 12).map((blog, Bitem) => (
                                    <div className="col-lg-6" key={Bitem}>
                                        <div className="blog_post_block_2">
                                            <div className="blog_post_image">
                                                <a >
                                                    <img src={blog.screens} alt="Best IT Course in India" />
                                                </a>
                                            </div>
                                            <div className="blog_post_content">
                                                <ul className="category_list unordered_list">
                                                    <li><a href="portfolio.html">{blog.thumb}</a></li>
                                                </ul>
                                                <h3 className="post_title">
                                                    <Link onClick={ClickHandler} to={`/${blog.slug.replace('Service-Home/', '')}`}>
                                                        {blog.title}
                                                    </Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    <ContactSection />
                    <CtaSection />
                    <Footer />
                    <Scrollbar />
                </main>
            </div>
        </Fragment>
    )
}

export default ServiceHome