
import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Hero3 from '../../components/hero3/hero3';
import EmpoweringSuccess from '../../components/business-consulting-components/EmpoweringSuccess/EmpoweringSuccess';
import ConsultingService from '../../components/business-consulting-components/ConsultingService/ConsultingService';
import BusinessTestimonial from '../../components/business-consulting-components/BusinessTestimonial/BusinessTestimonial';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import FaqSection from '../../components/software-company-components/FaqSection/FaqSection';
import Bg from '../../images/hero/business_consulting_hero_section_bg.jpg'
import shape1 from '../../images/hero/logo_google.svg'
import shape2 from '../../images/hero/Img 7.webp'
import shape3 from '../../images/hero/business_consulting_hero_image_2.webp'
import icon1 from '../../images/icons/icon_user_3.svg'
import icon2 from '../../images/icons/icon_wallet.svg'
import icon11 from '../../images/icons/icon_bulb.svg'
import icon12 from '../../images/icons/icon_user_rating.svg'
import icon3 from '../../images/icons/icon_hand_shake.svg'
import eImg from '../../images/hero/Img 9.webp'
import Img5 from '../../images/hero/Img 8.webp'
import storeServices from '../../api/storeStaffService';

const NearShoreStaffing = (props) => {
  const faqData = [
    {
        question: 'What is nearshore staffing?',
        answer: 'Nearshore staffing involves hiring skilled professionals from neighboring countries or regions. This approach offers a balance between cost-effectiveness and cultural proximity compared to traditional offshore outsourcing.',
    },
    {
        question: "How does XNovaQ's nearshore staffing process work?",
        answer: 'Our process involves:',
        listItem: [
            'Understanding your needs: We thoroughly understand your company culture, job requirements, and desired candidate profile.',
            'Sourcing and screening: We leverage our extensive network to find qualified candidates from our nearshore locations.',
            'Skill assessment and cultural fit evaluation: We assess candidates\' skills and cultural compatibility to ensure a smooth integration.',
            'Contract negotiation and onboarding: We handle the contract negotiation process and ensure a seamless onboarding experience for the nearshore team.',
            'Ongoing support: We provide ongoing support throughout the engagement, addressing any questions or concerns.'
        ]
    },
    {
        question: 'What are the benefits of nearshore staffing compared to offshore outsourcing?',
        answer: 'Nearshore staffing offers several advantages, including:',
        listItem: [
            'Reduced time zone differences',
            'Improved cultural compatibility',
            'Easier communication and collaboration',
            'Reduced travel costs',
            'Stronger data security and compliance'
        ]
    },
    {
        question: 'What regions does XNovaQ focus on for nearshore staffing?',
        answer: 'XNovaQ specializes in nearshore staffing from regions such as:',
        listItem: [
            'Latin America (Mexico, Costa Rica, Colombia)',
            'Eastern Europe (Poland, Ukraine, Romania)',
            'Southeast Asia (Philippines, Vietnam)'
        ]
    },
    {
        question: 'How does XNovaQ ensure quality and efficiency in nearshore staffing?',
        answer: 'XNovaQ implements rigorous quality control measures, including:',
        listItem: [
            'Skill assessments',
            'Cultural fit evaluations',
            'Regular performance reviews',
            'Client feedback mechanisms'
        ]
    },
    {
        question: 'What is the cost structure for nearshore staffing with XNovaQ?',
        answer: 'Our nearshore staffing services offer a competitive cost structure, often providing a more cost-effective solution compared to traditional offshore outsourcing while maintaining high quality.'
    },
    {
        question: 'How long does it typically take to hire nearshore talent through XNovaQ?',
        answer: 'The timeline for nearshore staffing can vary depending on factors like the specific role, industry, and availability of suitable candidates. However, XNovaQ strives to expedite the process while ensuring quality.'
    },
    {
        question: 'Can I visit the nearshore team or have them visit my office?',
        answer: 'Yes, we can facilitate visits between you and the nearshore team, allowing for face-to-face meetings and collaboration.'
    },
    {
        question: 'How does XNovaQ ensure data security and compliance in nearshore operations?',
        answer: 'XNovaQ prioritizes data security and compliance with relevant regulations. We implement robust measures to protect sensitive information and ensure adherence to industry standards.'
    },
    {
        question: 'Can I scale my nearshore team up or down as needed?',
        answer: 'Yes, XNovaQ offers flexible staffing models that allow you to easily scale your nearshore team to meet your evolving business needs.'
    }
];



    const reviews = {
        logo: shape1,
        rating: 5,
        count: 128,
    };

    const funFacts = [
        {
            value: 95,
            title: 'Success Rate',
            delay: '.1s',
            position: '',
            icon: icon1,
            altText: 'Success Icon',
        },
        {
            value: 32,
            title: 'Lower Cost To Hire',
            delay: '.2s',
            position: 'middle',
            icon: icon2,
            altText: 'Hire Icon',
        },
        {
            value: 47,
            title: 'Faster Time-To-Hire',
            delay: '.3s',
            position: '',
            icon: null,
            altText: '',
        },
    ];

    const servicesData = [
        {
            icon: icon11,
            title: 'Proximity and Cultural Affinity',
            description: 'Our nearshore locations offer a cultural proximity that fosters effective communication and collaboration.',
            altText: 'Bulb SVG Icon',
        },
        {
            icon: icon12,
            title: 'Quality Assurance',
            description: 'We implement rigorous quality control measures to ensure that our nearshore teams deliver exceptional results.',
            altText: 'User Rating SVG Icon',
        },
        {
            icon: icon3,
            title: 'Competitive Pricing',
            description: 'Our nearshore staffing solutions provide a cost-effective alternative to traditional offshore outsourcing.',
            altText: 'Hand Shake SVG Icon',
        },
    ];

    return (
        <Fragment>
            <div className='index_business_consulting'>
                <main className="page_content s2">
                    <Header />
                    <Hero3
                        backgroundImage={Bg}
                        title="Near"
                        title2="Shore Staffing"
                        subtitle="Accessing Top-Tier Talent Without the Distance"
                        buttonLabel="Get Started Today"
                        buttonLink="/contact"
                        image1={shape3}
                        image2={shape2}
                        // reviews={reviews}
                        funFacts={funFacts}
                        onClickHandler={() => {
                            console.log('Button clicked!');
                        }}
                    />
                    <EmpoweringSuccess
                        title="Why Choose XNovaQ for Nearshore Staffing ?"
                        image={eImg}
                        services={servicesData}
                    />
                    <ConsultingService
                        title="XNovaQ Nearshore Staffing Process"
                        subTitle="A Step-by-Step Guide to Finding Your Perfect Talent" 
                        services = {storeServices}
                        />
                    <BusinessTestimonial
                        title="Why Partner with XNovaQ for Nearshore Staffing"
                        content1="Our comprehensive services, combined with our deep understanding of the nearshore landscape, ensure a seamless and successful experience."
                        content2="Partner with XNovaQ and experience the benefits of nearshore staffing."
                        img={Img5} />
                      <FaqSection
                            title="F.A.Q."
                            subtitle="Need a Support?"
                            faqs={faqData}
                        /> 
                    <Footer />
                    <Scrollbar />
                </main>
            </div>
        </Fragment>
    )
}

export default NearShoreStaffing