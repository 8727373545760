import React from 'react';
import { Link } from "react-router-dom";
import Bg from '../../../images/backgrounds/bg_image_3.webp'


const ConsultingService = ({ title, subTitle,services = [] }) => {
    const serviceData = services
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business_consulting_service_section section_space" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="heading_block text-center">
                            <h2 className="heading_text mt-5">
                                {title}
                            </h2>
                            <p className="heading_description mb-0 text-dark">
                                {subTitle}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="business_consulting_services row">
                    {serviceData.map((service, srv) => (
                        <div className="col-lg-4" key={srv}>
                            <div className="iconbox_block">
                                <div className="iconbox_icon">
                                    <img src={service.sImg} alt={`${service.title} icon`} />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">
                                        <a>{service.title}</a>
                                    </h3>
                                    <p className="mb-0">
                                        {service.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="btns_group pb-0">
                    <Link onClick={ClickHandler} className="creative_btn" to="/contact">
                        <span className="btn_label bg-primary">Get Started Today</span>
                        <span className="btn_icon">
                            <i className="bg-primary fa-solid fa-arrow-up-right"></i>
                            <i className="bg-primary fa-solid fa-arrow-up-right"></i>
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default ConsultingService;