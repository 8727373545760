import React from 'react';



const WhyUs = ({ policies,img,title,subtitle }) => {
    return (
        <section className="service_section section_space bg-light">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="image_wrap">
                            <img src={img} alt="IT Courses in Coimbatore" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ps-lg-5">
                            <div className="heading_block">
                                <div className="heading_focus_text">
                                    <span className="badge bg-secondary text-white">{title}</span>
                                </div>
                                <h2 className="heading_text mb-0">
                                    {subtitle}
                                </h2>
                            </div>
                            <ul className="service_facilities_group unordered_list">
                                {policies && policies?.map((policy, index) => (
                                    <li key={index}>
                                        <strong className="iconbox_block layout_icon_left">
                                            <span className="iconbox_icon">
                                                <img src={policy.icon} alt={policy.title} />
                                            </span>
                                            <span className="iconbox_content">
                                                <strong className="iconbox_title mb-0">{policy.title}</strong>
                                            </span>
                                        </strong>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyUs;