import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import about1 from '../../images/about/aboutImage.webp'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import { Helmet } from 'react-helmet';

const About = (props) => {

    const [isOpen, setOpen] = useState(false)

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="about_section section_space mt-5">
            <Helmet>
                <title>XNovaQ | Top IT Career Program In Coimbatore</title>
                <meta
                    name="description"
                    content="Start your IT Career with Coimbatore's top-rated program. Get an IT job and salary from day 1, hands-on training, and expert mentorship."
                />
            </Helmet>
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="about_image_1">
                            <img src={about1} alt="IT Program In India" />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="about_content">
                            <div className="heading_block">
                                <div className="heading_focus_text">
                                    We are 😃
                                    <span className="badge bg-secondary text-white">XNovaQ</span>
                                </div>
                                <Link onClick={ClickHandler} to={"/course"}>
                                    <h2 className="heading_text">
                                        Employee Enablement Program
                                    </h2>
                                </Link>
                                <p className="heading_description mb-0">
                                    With our <span><Link onClick={ClickHandler} to={"/course"}>12 months EEP,</Link></span> discover a blended learning journey designed to enhance your technical and behavioral skills, preparing you for a successful career in the IT industry.
                                </p>
                            </div>
                            <ul className="icon_list unordered_list_block mb-5">
                                <li>
                                    <span className="icon_list_icon">
                                        <i className="fa-solid fa-circle fa-fw"></i>
                                    </span>
                                    <span className="icon_list_text">
                                        Comprehensive Curriculum: Master in-demand tech skills.
                                    </span>
                                </li>
                                <li>
                                    <span className="icon_list_icon">
                                        <i className="fa-solid fa-circle fa-fw"></i>
                                    </span>
                                    <span className="icon_list_text">
                                        Hands-On Experience: Work on real-world projects.
                                    </span>
                                </li>
                                <li>
                                    <span className="icon_list_icon">
                                        <i className="fa-solid fa-circle fa-fw"></i>
                                    </span>
                                    <span className="icon_list_text">
                                        Helping you to get better.
                                    </span>
                                </li>
                            </ul>
                            <ul className="btns_group unordered_list p-0 justify-content-start">
                                <li>
                                    <Link onClick={ClickHandler} to="/contact" className="btn">
                                        <span className="btn_label" data-text="Get Started">Get Started</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </Link>
                                </li>
                                {/* <li>
                                    <button className="video_btn" onClick={() => setOpen(true)}>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-play"></i>
                                        </span>
                                        <span className="btn_label">How We Works</span>
                                    </button>
                                // </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} /> */}
        </section>

    )
}

export default About;