import icon7 from '../images/icons/icon_analisis_2.svg';
import icon8 from '../images/icons/icon_process.svg';
import icon9 from '../images/icons/icon_chart_2.svg';
import icon10 from '../images/icons/icon_meeting.svg';
import icon11 from '../images/icons/icon_bulb_2.svg';
import icon12 from '../images/icons/icon_speed_miter.svg';

const hireStaffing = [
    { 
        Id: '1',
        sImg: icon7,
        title: 'Initial Consultation',
        slug: 'Initial-Consultation',
        description: 'We begin by understanding your specific hiring needs, company culture, and desired candidate profile.',
    },
    {
        Id: '2',
        sImg: icon8,
        title: 'Customized Recruitment Strategy',
        slug: 'Customized-Recruitment-Strategy',
        description: 'Based on your input, we develop a tailored recruitment strategy that effectively targets the right talent pool.',
    },
    {
        Id: '3',
        sImg: icon9,
        title: 'Candidate Sourcing and Screening',
        slug: 'Candidate-Sourcing-and-Screening',
        description: 'Our experienced recruiters utilize a combination of sourcing methods to identify potential candidates. We then conduct thorough screening to ensure they meet your qualifications.',
    },
    {
        Id: '4',
        sImg: icon10,
        title: 'Candidate Interviews',
        slug: 'Candidate-Interviews',
        description: 'We schedule interviews with promising candidates, ensuring a smooth and efficient process for both you and the candidates.',
    },
    {
        Id: '5',
        sImg: icon11,
        title: 'Reference Checks and Background Verification',
        slug: 'Reference-Checks-and-Background-Verification',
        description: 'To ensure the candidate\'s integrity and suitability, we conduct comprehensive reference checks and background verification.',
    },
    {
        Id: '6',
        sImg: icon12,
        title: 'Offer Extension and Onboarding',
        slug: 'Offer-Extension-and-Onboarding',
        description: 'Once the ideal candidate is selected, we assist with offer negotiation and onboarding to ensure a seamless transition.',
    },
];

export default hireStaffing;
