import React, { useState, useEffect } from 'react';
import '../../css/popup.css';
import popImage from '../../images/hero/popImage.webp';
import SimpleReactValidator from 'simple-react-validator';
import { useNavigate } from 'react-router-dom';


const Popup = () => {
  const navigate = useNavigate();
    const [forms, setForms] = useState({
        full_name: '',
        email: '',
        phone: '',
        city: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const phoneRegex = /^[6-9]\d{9}$/;
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const thanksNavigateClick = () => {
        navigate('/thanks');
      };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (!phoneRegex.test(forms.phone)) {
            setErrorMessage("Invalid phone number format. Must be a 10-digit number starting with 6, 7, 8, or 9.");
            return;
          }
        if (validator.allValid()) {
            try {
                const response = await fetch('https://xnovaq.com/xnova-register/xnovaq-register-api.php', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(forms)
                });
                const data = await response.json();
          
                if (data.status === 'success') {
                  setResponseMessage(data.message);
                  validator.hideMessages();
                  setForms({
                      full_name: '',
                      email: '',
                      phone: '',
                      city: '',
                  })
                  thanksNavigateClick();
                } else if (data.status === 'error') {
                  setResponseMessage(Object.values(data.message).join(', ')); 
                }
              } catch (error) {
                console.error('Error:', error);
                setResponseMessage('An error occurred while registering.');
              }
           
        } else {
            validator.showMessages();
        }
    };
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    },15000); // 15000 ms = 15 seconds
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false); 
  };

  return (
    <>
      {showPopup && (
        <div className="popup-modal">
          <div className="popup-container">
            <button className="close-button" onClick={closePopup}>X</button>
            <div className="popup-left">
              <div className="image-container">
                <img src={popImage} alt="Talents" />
              </div>
            </div>

            <div className="popup-right">
              <h2 className='primary'><mark>Start Your IT Career Today!</mark></h2>
              <p>Limited Seats: Enroll in XNovaQ's program today as a<span><mark> 'Programmer Trainee' </mark></span>
              <span>and start earning this month.</span></p>
            
          <form className="xb-item--form contact-from" onSubmit={(e) => submitHandler(e)}>
               
                    <div className="form-group input-with-icon">
                        {/* <label className="input_title" htmlFor="input_name">
                           
                        </label> */}
                        <input
                            value={forms.full_name}
                            type="text"
                            name="full_name"
                            className="form-control placeholderPop"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name" />
                            <i className="fa-regular fa-user input-icon"></i>
                        {validator.message('name', forms.full_name, 'required|alpha_space')}
                    </div>
                    <div className="form-group input-with-icon">
                        {/* <label className="input_title" htmlFor="input_email">
                            
                        </label> */}
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Email" />
                            <i className="fa-regular fa-envelope input-icon"></i>
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                    <div className="form-group input-with-icon">
                        {/* <label className="input_title" htmlFor="input_phone">
                            
                        </label> */}
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Phone Number" />
                            <i className="fa-regular fa-phone-volume input-icon"></i>
                        {validator.message('phone', forms.phone, 'required|phone')}
                        <p style={{ color: 'red' }}>{errorMessage}</p>
                    </div>
                    <div className="form-group input-with-icon">
                        <label className="input_title" htmlFor="input_name">
                        <i className="fa-solid fa-city input-icon"></i>
                        </label>
                        <input
                            value={forms.city}
                            type="text"
                            name="city"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="City" />
                        {validator.message('city', forms.city, 'required|alpha_space')}
                    </div>
           
            <button type="submit" className="register-button">Register Now</button>
          </form>
                
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
