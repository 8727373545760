import React from 'react'
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import ContactForm from '../ContactFrom/ContactForm'
import shape1 from '../../../images/shapes/shape_line_5.svg'
import shape2 from '../../../images/shapes/shape_line_6.svg'
import shape3 from '../../../images/shapes/shape_space_5.svg'
import { Link } from 'react-router-dom'


const ContactSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <section className="contact_section pb-80 bg-light section_decoration">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="contact_method_box">
                            <div className="heading_block">
                                <div className="heading_focus_text has_underline d-inline-flex mb-3" style={{ backgroundImage: `url(${Bg})` }}>
                                    You Are Here
                                </div>
                                <h2 className="heading_text mb-0">
                                    Let's Start
                                </h2>
                                <p className="heading_description mb-0">Initiating Your Journey to Success and Growth.</p>
                            </div>
                            <ul className="contact_method_list unordered_list_block">
                                <li>
                                    <a href="tel:+914224169202">
                                        <span className="icon">
                                            <i className="fa-solid fas fa-tty"></i>
                                        </span>
                                        <span className="text">(+91) 4224169202</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+916384907747">
                                        <span className="icon">
                                            <i className="fa-solid fa-phone-volume"></i>
                                        </span>
                                        <span className="text">(+91) 6384907747</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@xnovaq.com">
                                        <span className="icon">
                                            <i className="fa-solid fa-envelope"></i>
                                        </span>
                                        <span className="text">info@xnovaq.com</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <span className="icon">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </span>
                                        {/* <Link onClick={ClickHandler} to={`https://maps.app.goo.gl/dDeLGgrop4coyRuH9`} className="image_wrap">
                                        <span className="text">Hanudev Infopark, Coimbatore</span>
                                        </Link> */}
                                        <a href="https://maps.app.goo.gl/dDeLGgrop4coyRuH9" target="_blank" rel="noopener noreferrer" className="image_wrap">
                                            <span className="text">Hanudev Infopark, Coimbatore</span>
                                        </a>
                                    </a>
                                </li>
                            </ul>
                            <ul className="support_step unordered_list_block">
                                <li>
                                    <span className="serial_number">01</span>
                                    <span className="text">Fill the Registration Form</span>
                                </li>
                                <li>
                                    <span className="serial_number">02</span>
                                    <span className="text">Get A Free Personalized Consultation</span>
                                </li>
                                <li>
                                    <span className="serial_number">03</span>
                                    <span className="text">Visit XNovaQ Office</span>
                                </li>
                                <li>
                                    <span className="serial_number">04</span>
                                    <span className="text">Join Our Program</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="instant_contact_form">
                            <div className="small_title">
                                <i className="fa-solid fa-envelope-open-text"></i>
                                Let's Connect!
                            </div>
                            <h3 className="form_title">
                            Send us a message, and our team will call you back within 24 hours. 
                            </h3>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Techco Shape" />
            </div>
        </section>
    )
}

export default ContactSection;