import timg3 from '../images/team/team1.webp'
import timg4 from '../images/team/team2.webp'
import timg5 from '../images/team/team3.webp'
import timg6 from '../images/team/team4.webp'
import timg7 from '../images/team/team5.webp'
import timg8 from '../images/team/team6.webp'
import timg9 from '../images/team/team7.webp'



const Teams = [
   {
      Id: '0',
      tImg: timg3,
      name: 'Hemamalini',
      // slug: 'Atticus-Sterling',
      title: 'Software Engineer',
   },
   {
      Id: '1',
      tImg: timg4,
      name: 'Vijaya Kumar',
      // slug: 'Orion-Jasper',
      title: 'Data Engineer',
   },
   {
      Id: '2',
      tImg: timg5,
      name: 'Ramkumar',
      // slug: 'Atticus-Sterling',
      title: 'Java Developer',
   },
   {
      Id: '3',
      tImg: timg6,
      name: 'Praveen Raj',
      // slug: 'Orion-Jasper',
      title: 'Java Developer',
   },
   {
      Id: '4',
      tImg: timg7,
      name: 'Nisha',
      // slug: 'Atticus-Sterling',
      title: 'Frontend Developer',
   },
   {
      Id: '5',
      tImg: timg8,
      name: 'Anand Babu',
      // slug: 'Orion-Jasper',
      title: 'Software Developer',
   },
   {
      Id: '6',
      tImg: timg9,
      name: 'Manoj Kumar',
      // slug: 'Orion-Jasper',
      title: 'Automation Tester',
   },
]

export default Teams;