import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const ContactForm = (props) => {
    const navigate = useNavigate();
    const [forms, setForms] = useState({
        full_name: '',
        email: '',
        phone: '',
        message: '',
        city: '',
        highest_degree: '',
        job_title : ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const phoneRegex = /^[6-9]\d{9}$/;
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const thanksNavigateClick = () => {
        navigate('/thanks');
      };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (!phoneRegex.test(forms.phone)) {
            setErrorMessage("Invalid phone number format. Must be a 10-digit number starting with 6, 7, 8, or 9.");
            return; // Stop form submission if validation fails
          }
        // try {
        //     const response = await axios.post("http://localhost:5000/submit", forms);
        //     if (response.status === 200) {
        //       alert("Data submitted successfully!");
        //     }
        //   } catch (error) {
        //     console.error("There was an error submitting the form!", error);
        //   }
        if (validator.allValid()) {
            try {
                const response = await fetch('https://xnovaq.com/xnova-register/xnovaq-register-api.php', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(forms)
                });
                const data = await response.json();
          
                if (data.status === 'success') {
                  setResponseMessage(data.message);
                  validator.hideMessages();
                  setForms({
                      full_name: '',
                      email: '',
                      phone: '',
                      message: '',
                      city: '',
                      highest_degree: '',
                      job_title : ''
                  })
                  thanksNavigateClick();
                } else if (data.status === 'error') {
                  setResponseMessage(Object.values(data.message).join(', ')); 
                }
              } catch (error) {
                console.error('Error:', error);
                setResponseMessage('An error occurred while registering.');
              }
           
        } else {
            validator.showMessages();
        }
    };

    return (


        <form className="xb-item--form contact-from" onSubmit={(e) => submitHandler(e)}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_name">
                            <i className="fa-regular fa-user"></i>
                        </label>
                        <input
                            value={forms.full_name}
                            type="text"
                            name="full_name"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name" />
                        {validator.message('name', forms.full_name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_email">
                            <i className="fa-regular fa-envelope"></i>
                        </label>
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_phone">
                            <i className="fa-regular fa-phone-volume"></i>
                        </label>
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Phone Number" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                        <p style={{ color: 'red' }}>{errorMessage}</p>
                    </div>
                </div>
                {/* <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_company">
                            <i className="fa-regular fa-globe"></i>
                        </label>
                        <input
                            value={forms.company}
                            type="company"
                            name="company"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Company Name" />
                        {validator.message('company', forms.company, 'required')}
                    </div>
                </div> */}
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_name">
                        <i className="fa-solid fa-city"></i>
                        </label>
                        <input
                            value={forms.city}
                            type="text"
                            name="city"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="City" />
                        {validator.message('city', forms.city, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_highest_degree">
                        <i className="fa-solid fa-graduation-cap"></i>
                        </label>
                        <input
                            value={forms.highest_degree}
                            type="text"
                            name="highest_degree"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Highest Degree" />
                        {validator.message('highest_degree', forms.highest_degree, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_job_title">
                        <i className="fa-solid fa-briefcase"></i>
                        </label>
                        <input
                            value={forms.job_title}
                            type="text"
                            name="job_title"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Job Title" />
                        {validator.message('job_title', forms.job_title, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_textarea">
                            <i className="fa-regular fa-comments"></i>
                        </label>
                        <textarea
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.message}
                            type="text"
                            name="message"
                            className="form-control"
                            placeholder="How can we help you?">
                        </textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        <span className="btn_label" data-text="Send Request">Send Request</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ContactForm;