import React from 'react';
import { Link } from 'react-router-dom'
import Bg1 from '../../images/shapes/it_solution_hero_bg_1.svg'
import Bg2 from '../../images/hero/it_solution_hero_image_2.svg'
import heroImg from '../../images/hero/heroImage.webp'
import icon from '../../images/icons/icon_stars_trustpilot.svg'
import avatar1 from '../../images/avatar/avatar_1.webp'
import avatar2 from '../../images/avatar/avatar_2.webp'
import avatar3 from '../../images/avatar/avatar_3.webp'

const Hero = () => {

    return (
        <section className="it_solution_hero_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="it_solution_hero_content" style={{ backgroundImage: `url(${Bg1})` }}>
                            <div className="heading_focus_text mb-0 d-inline-flex align-items-center">👋 Hello! We Are <span className="badge bg-secondary text-white">XNovaQ</span></div>
                            <h1>
                            Start your IT career as a 'Programmer Trainee' today
                            </h1>
                            <p>
                            Complete a 12-month pathway to advanced IT skills with an intensive, skills-focused US-Based IT curriculum.
                            </p>
                            <ul className="btns_group unordered_list p-0 justify-content-start">
                                <li>
                                    <Link className="btn" to="/contact">
                                        <span className="btn_label" data-text="Get Started">Get Started</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <div className="review_short_info">
                                        <div className="d-flex">
                                            <img src={icon} alt="Techco - Trustpilot Review" />
                                            <span>4.8</span>
                                        </div>
                                        <div className="review_counter">From <b>200+</b> reviews</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 mt-lg-5 mt-0">
                    <img src={heroImg} className="img-fluid" alt="IT Program Academy In Coimbatore" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;