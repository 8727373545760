import icon7 from '../images/icons/icon_analisis_2.svg'
import icon8 from '../images/icons/icon_process.svg'
import icon9 from '../images/icons/icon_chart_2.svg'
import icon10 from '../images/icons/icon_meeting.svg'
import icon11 from '../images/icons/icon_bulb_2.svg'
import icon12 from '../images/icons/icon_speed_miter.svg'

const RecruitmentService = [
    { 
        Id: '1',
        sImg: icon7,
        title: 'Strategic Partnership',
        slug: 'Strategic-Partnership',
        description: 'We establish a deep understanding of your organization\'s unique hiring needs, culture, and objectives.',
    },
    {
        Id: '2',
        sImg: icon8,
        title: 'Tailored Talent Acquisition Strategy',
        slug: 'Tailored-Talent-Acquisition-Strategy',
        description: 'We develop a customized RPO strategy that aligns with your brand, market requirements, and desired time-to-hire.',
    },
    {
        Id: '3',
        sImg: icon9,
        title: 'Advanced Sourcing and Screening',
        slug: 'Advanced-Sourcing-and-Screening',
        description: 'Our expert recruiters leverage cutting-edge sourcing techniques and AI-powered tools to identify and attract top-tier candidates from passive and active talent pools.',
    },
    {
        Id: '4',
        sImg: icon10,
        title: 'Engaging Candidate Experience',
        slug: 'Engaging-Candidate-Experience',
        description: 'We prioritize a positive candidate experience throughout the process, utilizing effective communication, personalized interactions, and efficient assessments to evaluate candidates\' suitability.',
    },
    {
        Id: '5',
        sImg: icon11,
        title: 'Data-Driven Decision Making',
        slug: 'Data-Driven-Decision-Making',
        description: 'We leverage analytics and insights to optimize our recruitment strategies, measure performance, and identify areas for improvement.',
    },
    {
        Id: '6',
        sImg: icon12,
        title: 'Seamless Onboarding and Integration',
        slug: 'Seamless-Onboarding-and-Integration',
        description: 'We facilitate a smooth transition for new hires, ensuring they are well-prepared and integrated into your organization.',
    },
];

export default RecruitmentService