
import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Hero3 from '../../components/hero3/hero3';
import EmpoweringSuccess from '../../components/business-consulting-components/EmpoweringSuccess/EmpoweringSuccess';
import ConsultingService from '../../components/business-consulting-components/ConsultingService/ConsultingService';
import BusinessTestimonial from '../../components/business-consulting-components/BusinessTestimonial/BusinessTestimonial';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import FaqSection from '../../components/software-company-components/FaqSection/FaqSection';
import Bg from '../../images/hero/business_consulting_hero_section_bg.jpg'
import shape1 from '../../images/hero/logo_google.svg'
import shape2 from '../../images/hero/Img 10.webp'
import shape3 from '../../images/hero/business_consulting_hero_image_2.webp'
import icon1 from '../../images/icons/icon_user_3.svg'
import icon2 from '../../images/icons/icon_wallet.svg'
import icon11 from '../../images/icons/icon_bulb.svg'
import icon12 from '../../images/icons/icon_user_rating.svg'
import icon3 from '../../images/icons/icon_hand_shake.svg'
import eImg from '../../images/hero/Img 12.webp'
import Img5 from '../../images/hero/Img 11.webp'
import RecruitmentService from '../../api/recruitmentService';

const RecruitmentProcess = (props) => {
    const faqData = [
        {
            question: 'What is nearshore staffing?',
            answer: 'Nearshore staffing involves hiring skilled professionals from neighboring countries or regions. This approach offers a balance between cost-effectiveness and cultural proximity compared to traditional offshore outsourcing.',
        },
        {
            question: "How does XNovaQ's nearshore staffing process work?",
            answer: 'Our process involves:',
            listItem: [
                'Understanding your needs: We thoroughly understand your company culture, job requirements, and desired candidate profile.',
                'Sourcing and screening: We leverage our extensive network to find qualified candidates from our nearshore locations.',
                'Skill assessment and cultural fit evaluation: We assess candidates\' skills and cultural compatibility to ensure a smooth integration.',
                'Contract negotiation and onboarding: We handle the contract negotiation process and ensure a seamless onboarding experience for the nearshore team.',
                'Ongoing support: We provide ongoing support throughout the engagement, addressing any questions or concerns.'
            ]
        },
        {
            question: 'What is Recruitment Process Outsourcing (RPO)?',
            answer: 'RPO is a strategic outsourcing model where XNovaQ takes full responsibility for your entire recruitment process, from sourcing candidates to onboarding new hires. This allows you to focus on your core business while we handle the complexities of talent acquisition.',
        },
        {
            question: "How does XNovaQ's RPO process work?",
            answer: 'Our RPO process involves:',
            listItem: [
                'Understanding your needs: We thoroughly understand your company culture, job requirements, and desired candidate profile.',
                'Developing a tailored strategy: We create a customized RPO strategy aligned with your specific goals.',
                'Candidate sourcing and screening: We leverage our extensive network and advanced sourcing techniques to identify and attract top-tier candidates.',
                'Candidate assessment and engagement: We conduct thorough assessments and provide a positive candidate experience.',
                'Offer negotiation and onboarding: We assist with offer negotiation and ensure a smooth onboarding process.',
                'Performance management and analytics: We track key metrics and provide data-driven insights to optimize your recruitment efforts.'
            ]
        },
        {
            question: 'What are the benefits of RPO with XNovaQ?',
            answer: 'RPO with XNovaQ offers several benefits, including:',
            listItem: [
                'Improved efficiency: Our streamlined processes and expertise save you time and resources.',
                'Access to top talent: We leverage our extensive network to attract top-tier candidates.',
                'Data-driven insights: Our analytics help you make informed decisions and optimize your hiring strategies.',
                'Reduced costs: RPO can be a cost-effective solution compared to managing recruitment in-house.',
                'Enhanced candidate experience: We prioritize a positive candidate experience, leading to increased engagement and acceptance rates.'
            ]
        },
        {
            question: 'What industries does XNovaQ specialize in for RPO?',
            answer: 'XNovaQ offers RPO services across a wide range of industries, including:',
            listItem: [
                'Technology',
                'Healthcare',
                'Finance',
                'Engineering',
                'Manufacturing',
                'Retail',
                'And more'
            ]
        },
        {
            question: 'How long does the RPO process typically take?',
            answer: 'The timeline for RPO can vary depending on factors like the specific role, industry, and availability of suitable candidates. However, XNovaQ strives to expedite the process while ensuring quality.',
        },
        {
            question: 'What is the cost of RPO with XNovaQ?',
            answer: 'Our RPO fees are based on a variety of factors, including the scope of services, the complexity of the roles, and the volume of hires. We provide customized pricing models to suit your specific needs.',
        },
        {
            question: 'Can I interview candidates myself?',
            answer: 'Yes, you have the opportunity to interview candidates personally to assess their fit within your organization. XNovaQ can provide guidance and support throughout the interview process.',
        },
        {
            question: 'How do you ensure candidate quality?',
            answer: 'XNovaQ employs rigorous screening processes, including background checks, reference verification, and in-depth interviews to ensure the quality of candidates we present.',
        },
        {
            question: 'Can I scale up or down my RPO services as needed?',
            answer: 'Yes, our RPO solutions are scalable to accommodate your changing hiring needs. We can adjust our resources and services to match your evolving requirements.',
        },
        {
            question: 'How does XNovaQ differentiate itself from other RPO providers?',
            answer: 'XNovaQ stands out due to:',
            listItem: [
                'Deep industry expertise: Our recruiters have in-depth knowledge of various industries.',
                'Personalized service: We prioritize your unique needs and provide tailored solutions.',
                'Advanced technology: We leverage cutting-edge technology to streamline our processes and provide data-driven insights.',
                'Strong candidate network: We have access to a vast pool of qualified talent.',
                'Commitment to quality: We prioritize candidate quality and client satisfaction.'
            ]
        }
    ];
    
    


    const reviews = {
        logo: shape1,
        rating: 5,
        count: 128,
    };

    const funFacts = [
        {
            value: 95,
            title: 'Success Rate',
            delay: '.1s',
            position: '',
            icon: icon1,
            altText: 'Success Icon',
        },
        {
            value: 32,
            title: 'Lower Cost To Hire',
            delay: '.2s',
            position: 'middle',
            icon: icon2,
            altText: 'Hire Icon',
        },
        {
            value: 47,
            title: 'Faster Time-To-Hire',
            delay: '.3s',
            position: '',
            icon: null,
            altText: '',
        },
    ];

    const servicesData = [
        {
            icon: icon11,
            title: 'Enhanced Candidate Experience',
            description: 'We prioritize a positive candidate experience throughout the recruitment process, leading to increased candidate engagement and higher acceptance rates.',
            altText: 'Bulb SVG Icon',
        },
        {
            icon: icon12,
            title: 'Scalability and Flexibility',
            description: 'Our RPO solutions are designed to scale with your business needs, allowing you to adjust your hiring capacity as required.',
            altText: 'User Rating SVG Icon',
        },
        {
            icon: icon3,
            title: 'Reduced Cost per Hire',
            description: 'By outsourcing your recruitment process to XNovaQ, you can potentially reduce your overall hiring costs through improved efficiency and reduced administrative overhead.',
            altText: 'Hand Shake SVG Icon',
        },
    ];

    
    

    return (
        <Fragment>
            <div className='index_business_consulting'>
                <main className="page_content s2">
                    <Header />
                    <Hero3
                        backgroundImage={Bg}
                        title="Recruitment"
                        title2="Process Outsourcing"
                        subtitle="Partner with XNovaQ for End-to-End Recruitment Solutions"
                        buttonLabel="Get Started Today"
                        buttonLink="/contact"
                        image1={shape3}
                        image2={shape2}
                        // reviews={reviews}
                        funFacts={funFacts}
                        onClickHandler={() => {
                            console.log('Button clicked!');
                        }}
                    />
                    <EmpoweringSuccess
                        title="Why Choose XNovaQ for Recruitment Process Outsourcing (RPO) ?"
                        image={eImg}
                        services={servicesData}
                    />
                    <ConsultingService
                        title="XNovaQ Recruitment Process Outsourcing (RPO) Step-By-Step"
                        subTitle="A Step-by-Step Guide to Finding Your Perfect Talent" 
                        services = {RecruitmentService}/>
                    <BusinessTestimonial
                        title="Why Partner with XNovaQ for Recruitment Process Outsourcing"
                        content1="We offer tailored strategies, access to top talent, data-driven insights, and a seamless candidate experience, enabling you to achieve your recruitment goals efficiently and cost-effectively."
                        content2="Partner with XNovaQ and experience the benefits of our comprehensive RPO solutions."
                        img={Img5} />
                      <FaqSection
                            title="F.A.Q."
                            subtitle="Need a Support?"
                            faqs={faqData}
                        /> 
                    <Footer />
                    <Scrollbar />
                </main>
            </div>
        </Fragment>
    )
}

export default RecruitmentProcess