import icon7 from '../images/icons/icon_analisis_2.svg'
import icon8 from '../images/icons/icon_process.svg'
import icon9 from '../images/icons/icon_chart_2.svg'
import icon10 from '../images/icons/icon_meeting.svg'
import icon11 from '../images/icons/icon_bulb_2.svg'
import icon12 from '../images/icons/icon_speed_miter.svg'



const storeServices = [
        { 
            Id: '1',
            sImg: icon7,
            title: 'Needs Assessment',
            slug: 'Needs-Assessment',
            description: 'We begin by thoroughly understanding your specific requirements, including the role, duration, and desired skills.',
        },
        {
            Id: '2',
            sImg: icon8,
            title: 'Talent Sourcing',
            slug: 'Talent-Sourcing',
            description: 'Our experienced recruiters leverage their extensive network and advanced sourcing techniques to identify suitable candidates from our nearshore locations.',
        },
        {
            Id: '3',
            sImg: icon9,
            title: 'Skill Assessment and Screening',
            slug: 'Skill-Assessment-and-Screening',
            description: 'Candidates undergo rigorous assessments to ensure they possess the necessary skills and qualifications.',
        },
        {
            Id: '4',
            sImg: icon10,
            title: 'Cultural Fit Evaluation',
            slug: 'Cultural-Fit-Evaluation',
            description: 'We assess candidates\' cultural compatibility to ensure a smooth integration into your team.',
        },
        {
            Id: '5',
            sImg: icon11,
            title: 'Contract Negotiation and Onboarding',
            slug: 'Contract-Negotiation-and-Onboarding',
            description: 'We handle the contract negotiation process and ensure a seamless onboarding experience for the nearshore team.',
        },
        {
            Id: '6',
            sImg: icon12,
            title: 'Ongoing Support',
            slug: 'Ongoing-Support',
            description: 'Our dedicated team provides ongoing support throughout the engagement, addressing any questions or concerns and ensuring a seamless experience for both you and the nearshore team.',
        }
    
]    

export default storeServices;