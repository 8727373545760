import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../header/Header'
import Footer from '../../components/footer/Footer';
const ThankYou = () => {
  const navigate = useNavigate();
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '120vh',
    background: 'rgb(0 68 235 / 9%)',
    position: 'relative',
  };

  const contentStyle = {
    textAlign: 'center',
    maxWidth: '800px',
    padding: '2rem',
    // background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '10px',
  };

  const headingStyle = {
    fontSize: '3rem',
    marginBottom: '1rem',
    color: '#333',
  };

  const paragraphStyle = {
    fontSize: '1.2rem',
    color: '#555',
    marginBottom: '1.5rem',
  };

  const buttonContainerStyle = {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
  };

  const buttonStyle = {
    backgroundColor: '#0044eb',
    border: 'none',
    borderRadius: '50px',
    padding: '0.8rem 2rem',
    fontSize: '1rem',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const hoverButtonStyle = {
    backgroundColor: '#ff5252',
  };

  const circleStyle = {
    position: 'absolute',
    border: '2px solid #000',
    borderRadius: '50%',
    width: '200px',
    height: '200px',
    opacity: '0.3',
  };
  
  const changeHandlerNavigate = () => {
    navigate('/course');
  }
  const changeHandler = () => {
    navigate('/about');
  }


  return (
    <><Header />
    <div style={containerStyle}>
          <div style={contentStyle}>
              <h1 style={headingStyle}>Thank You</h1>
              <p style={paragraphStyle}>
                  Thank you for taking the first step toward success with XNovaQ. We’ve received your form submission and are thrilled to dive into your project.
              </p>
              <p style={paragraphStyle}>
                  We’ll be in touch with you within 24 hours. In the meantime, feel free to explore XNovaQ and our program.
              </p>
              <div style={buttonContainerStyle}>
                  <button style={buttonStyle} onClick={changeHandlerNavigate}>View Courses</button>
                  <button style={buttonStyle} onClick={changeHandler}>View About us</button>
              </div>
          </div>

          {/* <div style={{ ...circleStyle, top: '10%', left: '10%' }}></div>
    <div style={{ ...circleStyle, top: '80%', right: '10%' }}></div> */}
      </div><Footer /></>
  );
};

export default ThankYou;
