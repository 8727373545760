import React from 'react';
import Teams from '../../api/alumni'
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper';
import sImg1 from '../../images/icons/icon_facebook.svg'
import sImg2 from '../../images/icons/icon_twitter_x.svg'
import sImg3 from '../../images/icons/icon_linkedin.svg'
import sImg4 from '../../images/icons/icon_instagram.svg'


const TeamSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    

    return (
        <section className="team_section xb-hidden section_space mt-5">
            <div className="container">
                <div className="heading_block text-center">
                    {/* <div className="heading_focus_text">
                        <span className="badge bg-secondary text-white">Our Expert</span>
                        Team Members 😍
                    </div> */}
                    <h2 className="heading_text mb-0">
                    Alumni Spotlight 🌟
                    </h2>
                </div>

                <div className="team_carousel">
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        loop={true}
                        spaceBetween={30}
                        allowTouchMove={true}
                        centeredSlides={true}
                        pagination={{ clickable: true }}
                        speed={500}
                        // navigation={true}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                          }}
                          autoplay={{ delay: 1000 }}
                        breakpoints={{
                            576: {
                                slidesPerView: 2,
                            },
                            1025: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {Teams.slice(0, 9).map((team, tm) => (
                            <SwiperSlide key={tm} >
                                <div className="team_block">
                                    <div className="team_member_image">
                                        <a  className="image_wrap" aria-label="Team Details Button">
                                            <img src={team.tImg} alt="Alumni image" />
                                            {/* <i className="fa-solid fa-arrow-up-right"></i> */}
                                        </a>
                                    </div>
                                    <div className="team_member_info">
                                        <h3 className="team_member_name">
                                            <a>{team.name}</a>
                                        </h3>
                                        <h4 className="team_member_designation">{team.title}</h4>
                                        {/* <ul className="social_icons_block unordered_list justify-content-center">
                                            <li>
                                                <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>
                                                    <img src={sImg1} alt="Icon Facebook" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>
                                                    <img src={sImg2} alt="Icon Twitter X" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>
                                                    <img src={sImg3} alt="Icon Linkedin" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>
                                                    <img src={sImg4} alt="Icon Instagram" />
                                                </Link>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* <div className="container">
                    <div className="btns_group pb-0">
                        <Link className="btn btn-outline-light" to="/team">
                            <span className="btn_label" data-text="Our All Experts">Our All Experts</span>
                            <span className="btn_icon">
                                <i className="fa-solid fa-arrow-up-right"></i>
                            </span>
                        </Link>
                    </div>
                </div> */}
            </div>
        </section>
    );
}

export default TeamSection;