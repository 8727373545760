import React, { Fragment, useState } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import aImg from '../../images/about/about_image_3.png.png'
import aImg2 from '../../images/about/about_image_4.svg'
import ModalVideo from 'react-modal-video'
import PolicySection from './Policy';
import WhyUs from './WhyUs';
import CtaSection from '../../components/CtaSection/CtaSection';
import TeamSinglePage from '../TeamSinglePage/TeamSinglePage';
import Teams from '../../api/team'
import sIcon1 from '../../images/icons/telescope.svg'
import sIcon2 from '../../images/icons/dollar.svg'
import sIcon3 from '../../images/icons/global-monitor_svgrepo.com.svg'
import sIcon4 from '../../images/icons/icon_monitor.svg'
import sIcon5 from '../../images/icons/teach.svg'
import sIcon6 from '../../images/icons/pageset.svg'
import wImg from '../../images/about/group.webp'
import { Helmet } from 'react-helmet';

const AboutUsPage = (props) => {
    const Policy = [
        {
            title: 'Visionary Leadership',
            icon: sIcon1,
        },
        {
            title: 'Strong Foundation',
            icon: sIcon2,
        },
        {
            title: 'Student-Centric Approach',
            icon: sIcon3,
        },
        {
            title: 'Positive Company Culture',
            icon: sIcon4,
        },
      
        {
            title: 'Commitment to Excellence',
            icon: sIcon5,
        },
        {
            title: 'Strategic Partnerships',
            icon: sIcon6,
        }
    ]
    const [isOpen, setOpen] = useState(false)
    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
            <Helmet>
                <title>Best IT Academy in Coimbatore | XNovaQ</title>
                <meta
                    name="description"
                    content="XNovaQ: The leading IT career academy in Coimbatore. Get expert training, hands-on experience, and a job with us."
                />
            </Helmet>
                <PageTitle pagesub={'XNovaQ Team'} pageTop={'About'}/>
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="Best IT company in Coimbatore for Freshers" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="IT Courses in Coimbatore" />
                                        <button className="video_btn ripple_effect" onClick={() => setOpen(true)}>
                                            <span className="btn_icon">
                                                <i className="fa-solid fa-play"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="heading_block mb-0">
                            <div className="row justify-content-lg-between">
                                <div className="col-lg-4">
                                    <div className="heading_focus_text">
                                        About
                                        <span className="badge bg-secondary text-white">XNovaQ 🙂</span>
                                    </div>
                                    <h2 className="heading_text mb-0">
                                    We Are XNovaQ
                                    </h2>
                                </div>
                                <div className="col-lg-6">
                                    <p className="heading_description mb-0">
                                    XNovaQ, a subsidiary of QBrainX, is a premier IT career academy offering advanced training programs and comprehensive career development services. As an industry leader in direct hire staffing, contract staff hiring, and custom staffing solutions, we provide our talents with immense opportunities for growth and advancement. Located in the vibrant tech hub of Hanudev Infopark in Coimbatore, XNovaQ offers a dynamic and stimulating learning environment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PolicySection />
                <TeamSinglePage  teams={Teams}/>
                {/* <FeaturesSection /> */}
                {/* <TeamSection /> */}
                <WhyUs policies={Policy} img={wImg} title="Why Us" subtitle="What Sets Us Apart?" />
                <CtaSection />
            </main>
            <Footer />
            <Scrollbar />
            <ModalVideo 
            channel="youtube" 
            autoplay 
            isOpen={isOpen} 
            videoId="UNp3NSEGC9c"  // Use the video ID from the YouTube link
            onClose={() => setOpen(false)} 
             />
        </Fragment>
    )
};
export default AboutUsPage;
