import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Hero3 from '../../components/hero3/hero3';
import EmpoweringSuccess from '../../components/business-consulting-components/EmpoweringSuccess/EmpoweringSuccess';
import ConsultingService from '../../components/business-consulting-components/ConsultingService/ConsultingService';
import BusinessTestimonial from '../../components/business-consulting-components/BusinessTestimonial/BusinessTestimonial';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import FaqSection from '../../components/software-company-components/FaqSection/FaqSection';
import Bg from '../../images/hero/business_consulting_hero_section_bg.jpg'
import shape1 from '../../images/hero/logo_google.svg'
import shape2 from '../../images/hero/Img 4.webp'
import shape3 from '../../images/hero/business_consulting_hero_image_2.webp'
import icon1 from '../../images/icons/icon_user_3.svg'
import icon2 from '../../images/icons/icon_wallet.svg'
import icon11 from '../../images/icons/icon_bulb.svg'
import icon12 from '../../images/icons/icon_user_rating.svg'
import icon3 from '../../images/icons/icon_hand_shake.svg'
import eImg from '../../images/hero/Img 6.webp'
import Img5 from '../../images/hero/Img5.webp'
import contingentService from '../../api/contingentService';

const ContingentStaffing = (props) => {

    const faqData = [
        {
            question: 'What is contingent staffing?',
            answer: 'Contingent staffing, also known as temporary staffing or contract staffing, is a flexible hiring solution that allows businesses to meet their short-term workforce needs without committing to permanent employment. XNovaQ provides top-quality contingent staffing services to help you efficiently fill temporary roles.',
        },
        {
            question: "How does XNovaQ's contingent staffing process work?",
            answer: 'Our process involves:',
            listItem: [
                'Understanding your needs: We thoroughly understand your company culture, job requirements, and desired candidate profile.',
                'Sourcing and screening: We leverage our extensive network to find qualified candidates and conduct rigorous screening to ensure they meet your criteria.',
                'Candidate matching: We match the most suitable candidates with your temporary roles based on their skills and experience.',
                'Contract negotiation and onboarding: We handle the contract negotiation process and ensure a smooth onboarding experience for the temporary employee.',
                'Ongoing support: We provide ongoing support throughout the engagement, addressing any questions or concerns.',
            ],
        },
        {
            question: 'What industries does XNovaQ specialize in for contingent staffing?',
            answer: 'XNovaQ offers contingent staffing services across a wide range of industries, including:',
            listItem: [
                'Healthcare',
                'IT',
                'Finance',
                'Engineering',
                'Manufacturing',
                'Retail',
                'And more',
            ],
        },
        {
            question: 'How long does the contingent staffing process typically take?',
            answer: 'The timeline for contingent staffing can vary depending on factors like the specific role, industry, and availability of suitable candidates. However, XNovaQ strives to expedite the process while ensuring quality.',
        },
        {
            question: 'What is the cost of contingent staffing with XNovaQ?',
            answer: 'Our fees are competitive and based on a contingency model. We charge a fee upon successful placement, making it a cost-effective solution for your hiring needs.',
        },
        {
            question: 'Can I interview candidates myself?',
            answer: 'Yes, you have the opportunity to interview candidates personally to assess their fit within your organization. XNovaQ can provide guidance and support throughout the interview process.',
        },
        {
            question: 'How do you ensure candidate quality?',
            answer: 'XNovaQ employs rigorous screening processes, including background checks, reference verification, and in-depth interviews to ensure the quality of candidates we present.',
        },
        {
            question: "What if I'm not satisfied with the candidates presented?",
            answer: 'We maintain open communication and work closely with you to understand your preferences. If you\'re not satisfied with the initial candidates, we\'ll continue our search to find the perfect match.',
        },
        {
            question: 'Can I convert a temporary employee to a permanent position?',
            answer: 'Yes, if you\'re pleased with a temporary employee\'s performance, you can explore the possibility of converting them to a permanent position. We can assist with this process.',
        },
        {
            question: 'How does XNovaQ differentiate itself from other staffing agencies?',
            answer: 'XNovaQ stands out due to:',
            listItem: [
                'Deep industry expertise: Our recruiters have in-depth knowledge of various industries.',
                'Personalized service: We prioritize your unique needs and provide tailored solutions.',
                'Strong candidate network: We have access to a vast pool of qualified talent.',
                'Efficient processes: Our streamlined approach saves you time and resources.',
                'Commitment to quality: We prioritize candidate quality and client satisfaction.',
            ],
        },
    ];
    


    const reviews = {
        logo: shape1,
        rating: 5,
        count: 128,
    };

    const funFacts = [
        {
            value: 95,
            title: 'Success Rate',
            delay: '.1s',
            position: '',
            icon: icon1,
            altText: 'Success Icon',
        },
        {
            value: 32,
            title: 'Lower Cost To Hire',
            delay: '.2s',
            position: 'middle',
            icon: icon2,
            altText: 'Hire Icon',
        },
        {
            value: 47,
            title: 'Faster Time-To-Hire',
            delay: '.3s',
            position: '',
            icon: null,
            altText: '',
        },
    ];

    const servicesData = [
        {
            icon: icon11,
            title: 'Expert Talent Acquisition',
            description: 'Our team of seasoned recruiters possesses deep industry knowledge and a proven track record of sourcing and selecting the best candidates for your organization.',
            altText: 'Bulb SVG Icon',
        },
        {
            icon: icon12,
            title: 'Tailored Recruitment Solutions',
            description: 'We understand that every business has unique hiring needs. Our flexible approach ensures that our services align perfectly with your specific requirements.',
            altText: 'User Rating SVG Icon',
        },
        {
            icon: icon3,
            title: 'Streamlined Hiring Processes',
            description: 'We employ efficient and effective methodologies to accelerate the hiring process, saving you valuable time and resources.',
            altText: 'Hand Shake SVG Icon',
        },
    ];

    return (
        <Fragment>
            <div className='index_business_consulting'>
                <main className="page_content s2">
                    <Header />
                    <Hero3
                        backgroundImage={Bg}
                        title="Contingent"
                        title2="Staffing"
                        subtitle="Find the Perfect Temporary Talent with XNovaQ"
                        buttonLabel="Get Started Today"
                        buttonLink="/contact"
                        image1={shape3}
                        image2={shape2}
                        funFacts={funFacts}
                        onClickHandler={() => {
                            console.log('Button clicked!');
                        }}
                    />
                    <EmpoweringSuccess
                        title="Why XNovaQ should be your Contingent Staffing Partner ?"
                        image={eImg}
                        services={servicesData}
                    />
                    <ConsultingService
                        title="XNovaQ Contingent Staffing Process"
                        subTitle="A Step-by-Step Guide to Finding Your Perfect Talent Points"
                        services={contingentService} />
                    <BusinessTestimonial
                        title="Why Partner with XNovaQ for Contingent Staffing"
                        content1="We offer a comprehensive range of services designed to meet your temporary workforce needs, ensuring exceptional quality, efficiency, and value. Let us handle the complexities of contingent staffing while you focus on your core business objectives."
                        content2="Partner with XNovaQ and experience the difference."
                        img={Img5} />
                    <FaqSection
                        title="F.A.Q."
                        subtitle="Need a Support?"
                        faqs={faqData}
                    />
                    <Footer />
                    <Scrollbar />
                </main>
            </div>
        </Fragment>
    )
}
export default ContingentStaffing;