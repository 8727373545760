import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import ContactSection from '../../components/ContactSection';
import { Helmet } from 'react-helmet';

const ContactPage = (props) => {

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
            <Helmet>
                <title>Contact XNovaQ | Top Indian IT Program Academy</title>
                <meta
                    name="description"
                    content="Get in touch with our team for inquiries, admissions, and career counseling. Learn more about our top-rated IT programs in Coimbatore."
                />
            </Helmet>
                <PageTitle pageTitle={'Contact Us'} pagesub={'Us 😍'} pageTop={'Contact'} />
                <ContactSection />
                <CtaSection />
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default ContactPage;