import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import { Link } from "react-router-dom";
import Teams from '../../api/team'
import CountUp from 'react-countup';
import { useParams } from 'react-router-dom';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import sImg1 from '../../images/icons/icon_facebook.svg'
import sImg2 from '../../images/icons/icon_twitter_x.svg'
import sImg3 from '../../images/icons/icon_linkedin.svg'
import sImg4 from '../../images/icons/icon_instagram.svg'
import WhyUs from './../../main-component/AboutUsPage/WhyUs';

const TeamSinglePage = ({ teams}) => {
    const { slug } = useParams()

    const TeamDetails = Teams.find(item => item)

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                {/* <PageTitle pageTitle={TeamDetails?.name} pagesub={'Details 😍'} pageTop={'Team'} /> */}
                <section className="team_details_section section_space bg-light">
                    <div className="container">
                    {teams.map((team) => (
                            <div className="team_member_details_card" key={team.Id}>
                                <div className="team_member_image">
                                    <img src={team.tImg} alt={team.name} />
                                </div>
                                <div className="team_member_content">
                                    <h2 className="team_member_name">{team.name}</h2>
                                    <ul className="icon_list unordered_list_block">
                                        {team.title && (
                                            <li>
                                                <span className="icon_list_text">
                                                    <strong>Responsibility: </strong>
                                                    {team.title}
                                                </span>
                                            </li>
                                        )}
                                        {team.experience && (
                                            <li>
                                                <span className="icon_list_text">
                                                    <strong>Experience: </strong>
                                                    {team.experience}
                                                </span>
                                            </li>
                                        )}
                                        {team.email && (
                                            <li>
                                                <span className="icon_list_text">
                                                    <strong>Email: </strong>
                                                    {team.email}
                                                </span>
                                            </li>
                                        )}
                                       
                                    </ul>
                                    <div className="social_wrapper">
                                        <h3 className="social_title">LinkedIn</h3>
                                        <ul className="social_icons_block unordered_list">
                                            {team.name === 'Hariharan Jayasingh' && (
                                                <li>
                                                    <a
                                                        href="https://www.linkedin.com/in/hariharan-jayasingh-4144b621/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="image_wrap"
                                                    >
                                                        <img src={sImg3} alt="Icon LinkedIn" />
                                                    </a>
                                                </li>
                                            )}
                                             {team.name === 'R. Grace Lourds Beaulah' && (
                                                <li>
                                                    <a
                                                        href="https://www.linkedin.com/in/grace-beaulah-09448a78"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="image_wrap"
                                                    >
                                                        <img src={sImg3} alt="Icon LinkedIn" />
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <h3 className="details_item_info_title">XNovaQ Leadership Team</h3>
                        <p>
                        XNovaQ is led by a dynamic team of experienced professionals. Hariharan Jayasingh, the Managing Director, brings a wealth of industry expertise and a passion for innovation. Grace, the Director of the Talent Enablement Team, is dedicated to fostering a positive learning environment and ensuring that our students receive the highest quality education and support. Under their leadership, XNovaQ has established itself as a leading provider of tech education and career development services. </p>

                        <div className="row mb-5">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="funfact_block text-center">
                                    <div className="counter_value">
                                        <span className="odometer" data-count="100"><CountUp end={100} enableScrollSpy /></span>
                                        <span>%</span>
                                    </div>
                                    <h3 className="funfact_title mb-0">Positive Feedback from Industry Partners</h3>
                                    <div className="bottom_line bg-primary"></div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="funfact_block text-center">
                                    <div className="counter_value">
                                        <span className="odometer" data-count="85"><CountUp end={85} enableScrollSpy /></span>
                                        <span>%</span>
                                    </div>
                                    <h3 className="funfact_title mb-0">Increase in Average Salary Post-Program</h3>
                                    <div className="bottom_line bg-danger"></div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="funfact_block text-center">
                                    <div className="counter_value">
                                        <span className="odometer" data-count="98"><CountUp end={98} enableScrollSpy /></span>
                                        <span>%</span>
                                    </div>
                                    <h3 className="funfact_title mb-0">Student Satisfaction Post-Program</h3>
                                    <div className="bottom_line bg-secondary"></div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="funfact_block text-center">
                                    <div className="counter_value">
                                        <span className="odometer" data-count="77"><CountUp end={77} enableScrollSpy /></span>
                                        <span>%</span>
                                    </div>
                                    <h3 className="funfact_title mb-0">Increase In Student Proficiency</h3>
                                    <div className="bottom_line bg-warning"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Scrollbar />
        </Fragment>
    )
};
export default TeamSinglePage;