import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg';
import shape1 from '../../../images/shapes/shape_space_4.svg';
import shape2 from '../../../images/shapes/shape_angle_3.webp';

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = ({ title, subtitle, faqs = [], listItems }) => {
    const [open, setOpen] = useState(null);

    const toggle = (id) => {
        if (open === id) {
            setOpen(null); 
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq_section section_decoration">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                        {title}
                    </div>
                    <h2 className="heading_text mb-0">
                        {subtitle}
                    </h2>
                </div>

                <div className="faq_accordion accordion" id="faq_accordion">
                    <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                        {Array.isArray(faqs) && faqs.length > 0 ? (
                            faqs.map((faq, index) => (
                                <AccordionItem key={index} className="accordion-item">
                                    <AccordionHeader targetId={`${index}`}>
                                        {faq.question}
                                    </AccordionHeader>
                                    <AccordionBody accordionId={`${index}`} className="acc_body">
                                        <div className="text_a">A.</div>
                                        <p>{faq.answer}</p>
                                        {faq.listItem && faq.listItem.length > 0 && (
                                            <ul>
                                                {faq.listItem.map((item, i) => ( 
                                                    <li key={i}>
                                                        <i className=""></i>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </AccordionBody>
                                </AccordionItem>
                            ))
                        ) : (
                            <p>No FAQs available.</p>
                        )}
                    </Accordion>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape Angle" />
            </div>
        </section>
    );
};

export default FaqSection;