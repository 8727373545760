import React, { Fragment } from 'react';
import BusinessBlogSection from '../../components/business-consulting-components/BusinessBlogSection/BusinessBlogSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { Helmet } from 'react-helmet';


const HomePage3 = () => {

    return (
        <Fragment>
            <div className='index_business_consulting'>
                <main className="page_content s2">
                <Helmet>
                    <title>IT Course and Program in Coimbatore | XNovaQ</title>
                    <meta
                    name="description"
                    content="Transform your future with XNovaQ. Top IT courses in Coimbatore. Job-ready skills, expert guidance."
                     />
                </Helmet>
                    {/* <Header3 /> */}
                    {/* <Hero3 /> */}
                    {/* <PartnerSection /> */}
                    {/* <EmpoweringSuccess /> */}
                    {/* <ConsultingService /> */}
                    {/* <BusinessTestimonial /> */}
                    <BusinessBlogSection />
                    {/* <BusinessContact /> */}
                    {/* <BusinessFooter /> */}
                    <Scrollbar />
                </main>
            </div>
        </Fragment>
    )
};
export default HomePage3;